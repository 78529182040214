import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Volvo Group Connected Solutions" link="https://www.volvogroup.com/en-en/about-us/organization/other-entities/volvo-group-connected-solutions.html" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  2015-2020:
  At this large Telematics provider I have been part of building up their Cloud journey and posture on AWS for a number of years. We have envolved the organization from a classic Dev/QA/Ops organization to a true DevOps culture and have built a large cloud platform where we let the teams run micro services in a true DevOps fashion.   
    </ProjectCard>
    <ProjectCard title="Viaplay" link="https://www.viaplay.se" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
 2011:
 Consultancy project where we took part of their initial journey towards building up their cloud infrastructure on AWS and transforming their services to a micro service landscape
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      