import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`We're cloud experts with long experience architecting cloud platforms for large enterprises. `}</p>
    </blockquote>
    <p>{`Are you interested in your security posture when moving to the cloud? What about best practices for running micro services, serverless components or machine learning? Would you like to know how we can help you build a modern identity management solutions for your customers, your employees or both? Have you considered building a data lake to be able to see data patterns that your business generates over time? Whatever the project, we're here to help you achieve your goals! Just scroll down to reach out to us ;)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      